wh(x, y = x) {width: x; height: y}
circle(r) {wh(r); border-radius: r}
minwh(x, y = x) {min-width: x; min-height: y}
maxwh(x, y = x) {max-width: x; max-height: y}
flexXY(x, y = x) {display: flex; justify-content: x; align-items: y}
absPos(t, r = t, b = t, l = t){position: absolute; top: t; right: r; bottom: b; left: l}

shadow(c = #000000) {
	box-shadow: 0 3px 6px rgba(c, 0.16), 0 3px 6px rgba(c, 0.23)
	margin-top: 0
	transition: all 0.3s cubic-bezier(.25,.8,.25,1)
	&:hover {
		margin-top: -2px
		box-shadow: 0 7px 20px rgba(c, 0.25), 0 5px 10px rgba(c, 0.22)
	}
}